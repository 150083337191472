import { useEffect, useState } from "react";
import { useCommon } from "../../hooks/useCommon"
import { useApi } from "../../hooks/useApi"
import useAlert from "../../hooks/useAlert"
import { LiaCoinsSolid } from "react-icons/lia";
import { useAuth } from "../../hooks/useAuth";
import { Modal } from "react-bootstrap";
import Bkash from "./BKash";

const BuyCoins = () => {
    const [show, setShow] = useState(false);
    const [coins, setCoins] = useState(20);

    const cm = useCommon();
    const api = useApi();
    const alert = useAlert();
    const auth = useAuth();


    const handleClose = () => {
        setShow(false)
        setCoins(20)
    }


    useEffect(() => {
        coins < 20 && setCoins(20)
    }, [coins])

    const handleShow = () => {
        setShow(true);
    };

    return <>
        <button className="ms-3 btn btn-sm btn-warning rounded-pill px-3" onClick={handleShow}>{cm?.translate("Buy Coins")}</button>
        <Modal scrollable={true} show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title><LiaCoinsSolid className="fs-2" /> {cm?.translate("Buy Coins")}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-4 d-flex align-items-center justify-content-center">
                <input type="number" className="form-control w-25 fs-3" min={20} value={coins} onChange={e => setCoins(e.target.value)}></input>
                <span className="ms-2 fs-4">{cm?.translate("coins")}</span>
                <span className="ms-3 fs-4">=</span>
                <span className="ms-3 fs-4 text-danger">{coins} {cm.translate("Taka")}</span>
            </Modal.Body>
            <Modal.Footer>
                <Bkash quantity={coins} />
                <button className="btn btn-sm btn-secondary" onClick={handleClose}>{cm?.translate("Cancel")}</button>
            </Modal.Footer>
        </Modal >
    </>
}

export default BuyCoins