import { useState } from "react";
import { Modal } from "react-bootstrap"
import RenewPassword from "./RenewPassword";
import { useApi } from "../../hooks/useApi";
import useAlert from "../../hooks/useAlert";
import { useCommon } from "../../hooks/useCommon";


const PasswordRecovery = () => {
    const [show, setShow] = useState(false);
    const [emailOrPhone, setEmailOrPhone] = useState("");
    const [hasError, setHasError] = useState(true);
    const [showRenewModal, setShowRenewModal] = useState(false);
    const api = useApi();
    const alert = useAlert();
    const cm = useCommon();

    const handleClose = () => { setHasError(true); setShow(false); }
    const handleShow = () => {
        setShow(true)
    }

    const handleInput = (e) => {
        const { name, value } = e.target;
        setEmailOrPhone((prevValues) => {
            const updatedValues = { ...prevValues, [name]: value };
            if (updatedValues?.email_or_phone) {
                setHasError(false);
            } else {
                setHasError(true);
            }
            return updatedValues;
        });

    }

    const handleSend = async (e) => {
        try {
            const res = await api.recoverPassword(emailOrPhone);
            if (res) {
                handleClose(true);
                alert.show(res?.msg, "success");
                setShowRenewModal(true);
            }
        } catch (err) {
            alert.show(err?.response?.data?.msg, "error");
        } finally {
        }
    }

    return <>
        <button onClick={handleShow} className="w-100 btn btn-sm btn-outline-dark border-0">
            {cm?.translate("Forgot Password?")}
        </button>
        <Modal scrollable="true" show={show && !showRenewModal} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>{cm?.translate("Password Recovery")}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-4">
                <input type="text" placeholder="Email or Phone" className="form-control" name="email_or_phone" onChange={(e) => handleInput(e)}></input>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-sm btn-success" disabled={hasError} onClick={handleSend}>{cm?.translate("Send Recovery Code")}</button>
                <button className="btn btn-sm btn-secondary" onClick={handleClose}>Cancel</button>
            </Modal.Footer>
        </Modal>
        <RenewPassword showModal={showRenewModal} closeModal={setShowRenewModal} />
    </>
}
export default PasswordRecovery