import { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import PasswordRecovery from "../modals/PasswordRecovery";
import { useAuth } from "../../hooks/useAuth";
import { useApi } from "../../hooks/useApi";
import useAlert from "../../hooks/useAlert";
import RenewPassword from "../modals/RenewPassword";
import { useCommon } from "../../hooks/useCommon";

const Login = () => {
    const [emailOrPhone, setEmailOrPhone] = useState()
    const [password, setPassword] = useState()
    const [showRenewPasswordModal, setShowRenewPasswordModal] = useState(false)
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const auth = useAuth()
    const api = useApi()
    const alert = useAlert()
    const cm = useCommon()


    useEffect(() => {
        const action = searchParams.get("action");

        if (action === "renew-password") {
            setShowRenewPasswordModal(true)
        }
    }, [searchParams])


    const doLogin = async () => {
        try {
            const res = await api.loginAuth(emailOrPhone, password)
            if (res) {
                auth.login(res);
                if (res?.user?.user_settings?.disclaimer_agree) {
                    navigate("/mypage/profile")
                } else if (res?.user?.user_settings?.disclaimer_agree === false) {
                    navigate("/mypage/disclaimer")
                }
            }
        } catch (err) {
            alert.show(err?.response?.data?.msg || err, "error");
        }

    }

    const handleEnterPress = (e) => {
        if (e.key === "Enter" && emailOrPhone && password) {
            doLogin()
        }
    }

    return (
        <div className="col-sm-12 col-md-9 mx-auto">
            <div className="row m-0 gx-5" style={{ paddingTop: "6em", paddingBottom: "6em" }}>
                <div className="col-sm-12 col-md-6 d-flex flex-column justify-content-center align-items-center">
                    <h2 className="mb-0 text-success text-center fw-bold">{cm?.translate("Monozuna")}</h2>
                    <p className="text-center">
                        {cm?.translate("Advertise Locally, Grow Globally")}
                    </p>
                </div>
                <div className="col-sm-12 col-md-6 d-flex justify-content-center">
                    <div className="border rounded shadow-sm bg-light p-3" style={{ maxWidth: 450, minWidth: 350 }}>
                        <fieldset className="form-group">
                            <input
                                placeholder={cm?.translate("Email or Phone")}
                                type="text"
                                name="email_or_phone"
                                autoFocus={true}
                                autoCapitalize="none"
                                autoComplete="email_or_phone"
                                className="form-control"
                                required
                                onChange={(e) => setEmailOrPhone(e.target.value)}
                            />
                            <input
                                placeholder={cm?.translate("Password")}
                                type="password"
                                name="password"
                                autoComplete="password"
                                className="form-control mt-2"
                                required
                                onChange={(e) => setPassword(e.target.value)}
                                onKeyUp={(e) => handleEnterPress(e)}
                            />
                            <button onClick={doLogin} disabled={!emailOrPhone | !password && true} className="btn btn-sm btn-success w-100 mt-3 font-size-14">
                                <span
                                    id="spinner"
                                    className="spinner-border spinner-border-sm"
                                    style={{ display: "none" }}
                                ></span>
                                <span>{cm?.translate("Login")}</span>
                            </button>
                            <div className="mt-3 text-center">
                                <PasswordRecovery />
                                <RenewPassword showModal={showRenewPasswordModal} closeModal={setShowRenewPasswordModal} />
                            </div>
                            <div className="mt-3 text-center border-top pt-3">
                                <Link to="/register"
                                    className="btn btn-sm btn-outline-dark w-100"
                                >
                                    {cm?.translate("Create New Account")}
                                </Link>
                            </div>
                        </fieldset>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;