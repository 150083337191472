import { useState } from "react";
import { Modal } from "react-bootstrap"
import { useApi } from "../../hooks/useApi";
import { useCommon } from "../../hooks/useCommon";
import useAlert from "../../hooks/useAlert";

const ChangePassword = () => {
    const [show, setShow] = useState(false);
    const [passwords, setPasswords] = useState({
        old_password: "",
        new_password: "",
    });
    const [hasError, setHasError] = useState(true)
    const api = useApi();
    const cm = useCommon();
    const alert = useAlert();

    const handleClose = () => { setShow(false); }
    const handleShow = () => {
        setShow(true);
        setHasError(true);
    }

    const handleInput = (e) => {
        const { name, value } = e.target;
        setPasswords((prevValues) => {
            const updatedValues = { ...prevValues, [name]: value };

            if (updatedValues.old_password && updatedValues.new_password.length >= 6 &&
                /[a-zA-Z]/.test(updatedValues.new_password) &&
                /\d/.test(updatedValues.new_password)) {
                setHasError(false);
            } else {
                setHasError(true);
            }
            return updatedValues;
        });

    }

    const updatePassword = async () => {
        try {
            const res = await api.changePassword(passwords);
            if (res) {
                handleClose(true);
                alert.show(res?.msg, "success");
            }

        } catch (err) {
            alert.show(err?.response?.data?.msg, "error");
        }
    }

    return <>
        <button onClick={handleShow} className="align-self-stretch btn btn-sm btn-outline-success">
            {cm?.translate("Change Password")}
        </button>
        <Modal scrollable="true" show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>{cm?.translate("Change Password")}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-4">
                <input type="password" placeholder="Old Password" className="form-control" name="old_password" onChange={(e) => handleInput(e)}></input>
                <input type="password" placeholder="New Password" className="form-control mt-2" name="new_password" onChange={(e) => handleInput(e)}></input>
                <p className="font-size-9 mt-1 text-secondary px-1">
                    * {cm?.translate("At least 6 characters long")}<br />
                    * {cm?.translate("Must contain both number and alphabet")}
                </p>

            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-sm btn-success" disabled={hasError} onClick={updatePassword}>Change</button>
                <button className="btn btn-sm btn-secondary" onClick={handleClose}>Cancel</button>
            </Modal.Footer>
        </Modal>
    </>
}
export default ChangePassword