import { BsHeart, BsHeartFill } from "react-icons/bs";
import { BsEye } from "react-icons/bs";
import { TfiComment, TfiCommentAlt } from "react-icons/tfi";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useApi } from "../hooks/useApi";
import useAlert from "../hooks/useAlert";
import { useCommon } from "../hooks/useCommon";

const UserReaction = ({ post, size }) => {
    const [adPost, setAdPost] = useState()
    const api = useApi();
    const alert = useAlert();
    const cm = useCommon();

    useEffect(() => {
        setAdPost(post)
    }, [post])

    useEffect(() => {
        Object.keys(cm?.refreshAdInMyAds).length > 0 && post?.alias_id === cm?.refreshAdInMyAds?.alias_id && getAdDetailFromApi(cm?.refreshAdInMyAds?.alias_id)
    }, [cm?.refreshAdInMyAds])

    const getAdDetailFromApi = async (aliasId) => {
        try {
            const res = await api?.getAdDetail(aliasId);
            if (res) {
                setAdPost(res)
            }
        } catch (err) {
            alert.show(err?.response?.data?.msg || err, "error")
            cm.showLoader(false);
        }
    };

    const handleLike = async () => {
        try {
            const res = await api.updatePostLike(post.alias_id)
            setAdPost(res)
            cm?.setAdsCache({})
            cm?.setChangedInUser(post?.owner)
        } catch (err) {
            if (err?.response?.status === 404) {
                alert.show("Login is required", "info");
            } else {
                alert.show(err?.response?.data?.msg, "error");
            }

        }
    };

    const formatCount = (count) => {
        return count < 1000 ? count : count > 1000 & count < 1000000 ? count / 1000 + "K" : count / 1000000 + "M"
    }

    return (
        <div className="d-flex align-items-center">
            <div className="d-flex align-items-center">
                {adPost?.is_viewed_by_auth_user ? <BsEye className={size} style={{ color: "green" }} title="View" /> : <BsEye className={size} title="View" />}
                <span className="ms-1 font-size-10">{adPost?.total_view > 0 && formatCount(adPost?.total_view)}</span>
            </div>
            <Link
                className="link-dark mx-2 text-decoration-none d-flex align-items-center"
                to={"/ads/" + adPost?.alias_id + "/#comments"}
            >
                {adPost?.is_commented_by_auth_user ? <TfiCommentAlt className={size} style={{ color: "green" }} title="Comment" /> : <TfiComment className={size} title="Comment" />}
                <span className="ms-1 font-size-10">{adPost?.total_comment > 0 && formatCount(adPost?.total_comment)}</span>
            </Link>
            <button
                className="btn btn-sm p-0 border-0  d-flex align-items-center"
                onClick={handleLike}
            >
                {adPost?.is_liked_by_auth_user ? <BsHeartFill className={size} title="Like" style={{ color: "green" }} /> : <BsHeart className={size} title="Like" />}
                <span className="ms-1 font-size-10">{adPost?.total_like > 0 && formatCount(adPost?.total_like)}</span>
            </button>
        </div>
    );
};
export default UserReaction;
