import { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { BsSearch } from "react-icons/bs";
import { useNavigate } from 'react-router-dom';
import { useCommon } from "../hooks/useCommon";

const Search = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const navigate = useNavigate();
    const cm = useCommon();

    const handleSearch = (e) => {
        if (e.key === 'Enter') {
            if (e.target.value) {
                navigate("/ads/?search=" + e.target.value)
            } else {
                navigate("/ads")
            }
            setShow(false);
        }
    }

    return <>
        <button onClick={handleShow} className="align-self-stretch btn btn-outline-success border-0 not-focusable rounded d-flex justify-content-center align-items-center ms-1">
            <BsSearch className="fs-4" />
            <span className="d-inline-block ms-2">{cm?.translate("Search")}</span>
        </button>
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Body>
                <Form.Control
                    type="search"
                    placeholder="Search"
                    autoFocus
                    onKeyUp={(e) => handleSearch(e)}
                />
            </Modal.Body>
        </Modal>
    </>
}
export default Search;