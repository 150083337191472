import { ImSpinner9 } from "react-icons/im"

const Loading = ({ option }) => {
    return option?.show &&
        <div className="most-top position-fixed top-0 start-0 end-0 bottom-0 bg-white bg-opacity-10 d-flex align-items-center justify-content-center">
            <div className="py-4 d-flex justify-content-center align-items-center rounded-3  bg-white text-success fs-3 fw-semibold shadow-lg w-sm-100-md-25 mx-2">
                {<ImSpinner9 className="spin me-3" />}
                {option?.title ? option?.title : "Loading.."}
            </div>
        </div>
}
export default Loading