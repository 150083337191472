import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useApi } from "../../hooks/useApi"
import useAlert from "../../hooks/useAlert"
import { useCommon } from "../../hooks/useCommon";
import MemberThumb from "../MemberThumb";
import SendSms from "../modals/SendSms";
import SendMail from "../modals/SendMail";
import { useAuth } from "../../hooks/useAuth";

const AdOwner = ({ post }) => {
    const [show, setShow] = useState(false);
    const [owner, setOwner] = useState({});

    const api = useApi();
    const auth = useAuth();
    const cm = useCommon();
    const alert = useAlert();


    const handleClose = () => { setShow(false); }

    const getOwnerDetail = async () => {
        try {
            const res = await api.getUserDetail(post?.owner?.alias_id);
            if (res) {
                setOwner(res)

            }
        } catch (err) {
            alert.show(err?.message, "error")
        }
    };

    useEffect(() => {
        show && post && getOwnerDetail()
    }, [show])

    const extractEmail = (user) => {
        if (user?.user_business_profile?.company_email) {
            return user?.user_business_profile?.company_email;
        } else if (user?.email_or_phone?.includes("@")) {
            return user?.email_or_phone;
        } else if (user?.user_profile?.email_or_phone?.includes("@")) {
            return user?.user_profile?.email_or_phone;
        } else {
            return false
        }
    }

    const extractPhone = (user) => {
        if (user?.user_business_profile?.company_phone) {
            return user?.user_business_profile?.company_phone;
        } else if (!user?.email_or_phone?.includes("@")) {
            return user?.email_or_phone;
        } else if (!user?.user_profile?.email_or_phone?.includes("@")) {
            return user?.user_profile?.email_or_phone;
        } else {
            return false
        }
    }

    return <>
        <button className="btn btn-sm" onClick={() => setShow(true)}>{post?.owner?.user_business_profile?.company_name || post?.owner?.user_profile?.name || post?.owner?.email_or_phone}</button>

        <Modal size="lg" scrollable="true" show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>{cm?.translate("Ad. Owner")}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-4 font-size-10">
                <MemberThumb user={post?.owner} size={64} />
                <div className="row m-0 d-flex align-items-center mb-1 mt-2 bg-light p-2 px-0 ">
                    <div className="col-2">
                        <span>{cm?.translate("ID")}</span>
                    </div>
                    <div className="col">
                        {owner?.email_or_phone}
                    </div>
                </div>
                <div className="row m-0 d-flex align-items-center mb-1 bg-light p-2 px-0">
                    <div className="col-2">
                        <span>{cm?.translate("Name")}</span>
                    </div>
                    <div className="col">
                        {owner?.user_profile?.name}
                    </div>
                </div>
                <div className="row m-0 d-flex align-items-center mb-1 bg-light p-2 px-0">
                    <div className="col-2">
                        <span>{cm?.translate("Company")}</span>
                    </div>
                    <div className="col">
                        {owner?.user_business_profile?.company_name}
                    </div>
                </div>
                <div className="row m-0 d-flex align-items-center mb-1 bg-light p-2 px-0">
                    <div className="col-2">
                        <span>{cm?.translate("Business Email")}</span>
                    </div>
                    <div className="col">
                        {owner?.user_business_profile?.company_email}
                    </div>
                </div>
                <div className="row m-0 d-flex align-items-center mb-1 bg-light p-2 px-0">
                    <div className="col-2">
                        <span>{cm?.translate("Business Phone")}</span>
                    </div>
                    <div className="col">
                        {owner?.user_business_profile?.company_phone}
                    </div>
                </div>

                <div className="row m-0 d-flex align-items-center mb-1 bg-light p-2 px-0">
                    <div className="col-2">
                        <span>{cm?.translate("Location")}</span>
                    </div>
                    <div className="col">
                        {owner?.user_business_profile?.location?.name}
                    </div>
                </div>

                <div className="row m-0 d-flex align-items-center mb-1 bg-light p-2 px-0">
                    <div className="col-2">
                        <span>{cm?.translate("Address")}</span>
                    </div>
                    <div className="col">
                        {owner?.user_business_profile?.address}
                    </div>
                </div>

                <div className="row m-0 d-flex align-items-center mb-1 bg-light p-2 px-0">
                    <div className="col-2">
                        <span>{cm?.translate("Website")}</span>
                    </div>
                    <div className="col">
                        {owner?.user_business_profile?.web_link}
                    </div>
                </div>

                <div className="row m-0 d-flex align-items-center mb-1 bg-light p-2 px-0">
                    <div className="col-2">
                        <span>{cm?.translate("Total Ad.")}</span>
                    </div>
                    <div className="col">
                        {owner?.ads}
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                {extractPhone(owner) && <SendSms receiver={extractPhone(owner)} />}
                {extractEmail(owner) && <SendMail receiver={extractEmail(owner)} sender={extractEmail(auth?.authUser)} adminMessage={true} />}

                <Button className="btn btn-sm btn-secondary" onClick={handleClose}>{cm?.translate("Cancel")}</Button>

            </Modal.Footer>
        </Modal>
    </>
}
export default AdOwner;