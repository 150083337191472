import { useEffect, useState } from "react"
import { Button } from "react-bootstrap"
import { BiSolidSave } from "react-icons/bi"
import { CgSpinner } from "react-icons/cg"
import { useCommon } from "../hooks/useCommon"

const SaveButton = ({ handleSave, disabled, type = "button", label = "Save" }) => {
    const [showSpin, setShowSpin] = useState(false)

    useEffect(() => {
        setShowSpin(false)
    }, [])

    const clickSave = async () => {
        setShowSpin(true);
        const res = await handleSave();
        if (res) {
            setShowSpin(false);
        }
    }


    return <Button type={type} disabled={showSpin | disabled} variant="success" size="sm" onClick={clickSave} className="d-flex">
        {!showSpin && <BiSolidSave className="fs-5" />}
        {showSpin && <CgSpinner className="spin fs-5" />}
        <span className="ms-1">{label}</span>
    </Button>
}
export default SaveButton