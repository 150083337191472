import React from "react";
import UserReaction from "./UserReaction";
import PostBasicInfo from "./PostBasicInfo";
import PostTemplate2Media from "./PostTemplateMedia";
import MemberThumb from "./MemberThumb";

const PostTemplate2 = ({ post }) => {

    return (
        <div
            className="col-sm-12 col-md-4 col-lg-3"
        >
            <div className="d-flex flex-column py-1">
                <div className="">
                    <PostTemplate2Media post={post} thumbSize="post-thumb" />
                </div>
                <div className="bg-white  py-1 d-flex flex-row justify-content-start">
                    <div className="pt-1">
                        <MemberThumb user={post?.owner} size={36} />
                    </div>
                    <div className="ps-2">
                        <PostBasicInfo post={post} />
                        <UserReaction post={post} />
                        <span className="font-size-10 fw-semibold ellipsis-1 mt-1">
                            {post?.ad_order_option?.unit_price && post?.ad_order_option?.unit_price + " " + post?.ad_order_option?.currency}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default PostTemplate2;
