import React from "react";
import { useNavigate } from "react-router-dom";
import MyPageMenu from "./MyPageMenu";
import { useAuth } from "../hooks/useAuth";
import { useCommon } from "../hooks/useCommon";

const Header = () => {
    const auth = useAuth()
    const cm = useCommon()
    const navigate = useNavigate();
    const toIndex = () => {
        let path = "/";
        navigate(path);
    };

    const getNamePart = (name) => {
        const nameParts = name.split(" ");
        const mypage = cm?.translate("My Page")
        if (nameParts.length > 1) {
            return mypage + " - " + nameParts[0]
        } else {
            return mypage + " - " + name
        }
    }

    return (
        <div
            className="border-bottom border-success border-3 border-opacity-75"
            style={{ background: "#d0ead175" }}
        >
            <div className="mx-auto" style={{ maxWidth: 1440 }}>
                <div className="col-sm-12 col-md-11 mx-auto d-flex justify-content-between align-items-center py-2 px-2 px-md-0">
                    <div className="d-flex align-items-end">

                        <a href="/">
                            <img src={process.env.PUBLIC_URL + "/logo.png"} alt="logo" style={{ width: 170 }} />
                        </a>
                        <span
                            className="font-size-8 ms-1 lh-1 text-success"
                            style={{ marginBottom: 10 }}
                        >
                            bd
                        </span>
                    </div>
                    <div className="d-print-none">
                        <button
                            onClick={toIndex}
                            className="btn btn-sm btn-outline-success border-0 rounded-pill px-3 d-none d-md-inline-block"
                        >
                            {cm?.translate("Ads.")}
                        </button>

                        <button
                            className="btn btn-sm btn-outline-success border-0 rounded-pill px-3"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasMypageMenu"
                            aria-controls="offcanvasMypageMenu"
                        >
                            {auth.authUser?.user_profile?.name ? getNamePart(auth.authUser?.user_profile?.name) : cm?.translate("My Page")}
                        </button>
                        <MyPageMenu />

                    </div>
                </div>
            </div>
        </div>
    );
};
export default Header;
