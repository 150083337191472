import React, { useEffect, useState } from "react";
import { MdClose } from "react-icons/md";
import { useApi } from "../../hooks/useApi"
import { useAuth } from "../../hooks/useAuth";
import useAlert from "../../hooks/useAlert";
import { useCommon } from "../../hooks/useCommon";
import AdOwner from "../admin/AdOwner";
import { FaCheck } from "react-icons/fa";
import moment from "moment";
import SendMail from "../modals/SendMail";
import SendSms from "../modals/SendSms";


const AdTemplateForAdmin = ({ ad }) => {
    const [adPost, setAdPost] = useState()

    const api = useApi();
    const auth = useAuth();
    const alert = useAlert();
    const cm = useCommon();


    useEffect(() => {
        setAdPost(ad)
    }, [])

    const renderMediaFiles = () => {
        if (ad?.media.length > 0) {
            return ad?.media?.map((m, i) => <img src={process.env.REACT_APP_MEDIA_URL + (m?.media_url_resized100x100 || m?.media_url_resized || m?.media_url)} key={"img_" + ad?.alias_id + "_" + i} alt="img" className="me-1" style={{ width: 36, height: 36, objectFit: "cover" }} />)
        } else {
            return <img src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" key={"img_" + ad?.alias_id} alt="no_img" className="me-1 ratio ratio-1x1" style={{ width: 36, height: 36, objectFit: "contain" }} />
        }
    }


    const renderCategories = () => {
        const uniq = (items) => [...new Set(items)];
        const uniqueGroups = uniq(cm?.categories.map((item) => item?.group?.name));
        return uniqueGroups?.map((g, ix) => {
            return <optgroup key={g + "_" + ix} label={g} style={{ background: "#fff" }}>
                {cm?.categories && cm?.categories?.map(c => c?.group?.name === g && <option key={"cat_" + c.id} value={c?.id} >{cm?.translate(c?.name)} ({cm?.translate(c?.total_ads, cm?.language, true)})</option>)}
            </optgroup>
        })
    }

    const handleToggleApproval = async () => {
        try {
            cm.showLoader(true, "Updating..")
            const res = await api.toggleAdApproval(adPost?.alias_id)
            if (res) {
                setAdPost(res)
                alert.toast("Approval Updated", "success")
            }
            cm.showLoader(false)
        } catch (err) {
            alert.show(err?.response?.data?.msg || err, "error")
            cm.showLoader(false)
        }
    }

    return (
        <div
            className="col-sm-12 mb-3 border border-2"
        >
            <div className="row m-0 bg-light border-bottom">
                <div className="col-3 p-2 d-flex align-items-center">{cm?.translate("Alias Id")}</div>
                <div className="col bg-white p-2 text-break"><a href={"/ads/" + adPost?.alias_id} target="_blank" rel="noreferrer" className="link-success text-decoration-none">{adPost?.alias_id}</a></div>
            </div>
            <div className="row m-0 bg-light border-bottom">
                <div className="col-3 p-2 d-flex align-items-center">{cm?.translate("Photos")}</div>
                <div className="col bg-white p-2">
                    {renderMediaFiles()}
                </div>
            </div>
            <div className="row m-0 bg-light border-bottom">
                <div className="col-3 p-2 d-flex align-items-center">{cm?.translate("Category")}</div>
                <div className="col bg-white p-2">
                    {adPost?.category?.name}
                </div>
            </div>
            <div className="row m-0 bg-light border-bottom">
                <div className="col-3 p-2 d-flex align-items-center">{cm?.translate("Title")}</div>
                <div className="col bg-white p-2">
                    {adPost?.title}
                </div>
            </div>
            <div className="row m-0 bg-light border-bottom">
                <div className="col-3 p-2 d-flex align-items-start">{cm?.translate("Description")}</div>
                <div className="col bg-white p-2">
                    <p className="overflow-scroll mb-0" style={{ maxHeight: 300 }} dangerouslySetInnerHTML={{ __html: adPost?.description }}></p>
                </div>
            </div>
            <div className="row m-0 bg-light border-bottom">
                <div className="col-3 p-2 d-flex align-items-start">{cm?.translate("Created on")}</div>
                <div className="col bg-white p-2">
                    {moment(adPost?.created).format("YYYY-MM-DD")}
                </div>
            </div>
            <div className="row m-0 bg-light border-bottom">
                <div className="col-3 p-2 d-flex align-items-start">{cm?.translate("Expire on")}</div>
                <div className="col bg-white p-2">
                    {moment(adPost?.expire).format("YYYY-MM-DD")}
                </div>
            </div>
            <div className="row m-0 bg-light border-bottom">
                <div className="col-3 p-2 d-flex align-items-start">{cm?.translate("Approval")}</div>
                <div className="col bg-white p-2">
                    {adPost?.is_approved ? <FaCheck className="text-success" /> : <MdClose className="text-danger fs-5" />}
                </div>
            </div>
            <div className="row m-0 bg-light border-bottom">
                <div className="col-3 p-2 d-flex align-items-center">{cm?.translate("Ad. Owner")}</div>
                <div className="col bg-white p-2">
                    <AdOwner post={adPost} />
                </div>
            </div>

            <div className="bg-light p-2 d-flex flex-row flex-wrap justify-content-end rounded-bottom">
                {adPost?.is_approved && <button className="btn btn-sm btn-white" onClick={handleToggleApproval}>Revoke Approval</button>}
                {!adPost?.is_approved && <button className="btn btn-sm btn-white" onClick={handleToggleApproval}>Approve</button>}
            </div>

        </div>
    );
};
export default AdTemplateForAdmin;
