import React from "react";
import { Link } from "react-router-dom";
import { BsThreeDotsVertical } from "react-icons/bs";
import Loading from "./Loading";
import { useCommon } from "../hooks/useCommon";
import useIsScrolled from "../hooks/useIsScrolled";
import { MdOutlineKeyboardDoubleArrowUp } from "react-icons/md";

const Footer = () => {
    const cm = useCommon();
    const isScrolled = useIsScrolled();

    return (
        <>
            <div className={"col-sm-12 col-md-10 mx-auto border-top mb-5 d-print-none"}>
                <div className="d-flex flex-wrap justify-content-center align-items-center py-1 font-size-10 px-4 px-md-0">
                    <Link to="/docs?title=about" className="text-decoration-none link-success">{cm?.translate("About")}</Link>
                    <BsThreeDotsVertical className="mx-1 text-secondary" />
                    <Link to="/docs?title=contact" className="text-decoration-none link-success">{cm?.translate("Contact")}</Link>
                    <BsThreeDotsVertical className="mx-1 text-secondary" />
                    <Link to="/docs?title=terms" className="text-decoration-none link-success">{cm?.translate("Terms & Condition")}</Link>
                    <BsThreeDotsVertical className="mx-1 text-secondary" />
                    <Link to="/docs?title=privacy_policy" className="text-decoration-none link-success">{cm?.translate("Privacy Policy")}</Link>
                    <BsThreeDotsVertical className="mx-1 text-secondary" />
                    <Link to="/docs?title=faq" className="text-decoration-none link-success">{cm?.translate("FAQ")}</Link>
                    <BsThreeDotsVertical className="mx-1 text-secondary" />
                    <Link to="/docs?title=news" className="text-decoration-none link-success">{cm?.translate("News")}</Link>
                </div>
            </div>
            <div className="sticky-bottom d-flex justify-content-between p-2">
                <div>
                    {/* <span className="btn btn-light px-3 rounded-pill">Call for your advertisement 01321747471</span> */}
                </div>
                {isScrolled && <button className="btn btn-sm btn-danger bg-opacity-50 shadow-sm me-3 mb-3" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
                    <MdOutlineKeyboardDoubleArrowUp className="fs-3" />
                </button>}
            </div>

            <Loading option={cm.showLoading} />
        </>
    );
};
export default Footer;
