import { useEffect, useState } from "react";
import { useCommon } from "../../hooks/useCommon";
import useRenewAuth from "../../hooks/useRenewAuth";
import GeneralProfile from "./GeneralProfile";
import BusinessProfile from "./BusinessProfile";
import { useSearchParams } from "react-router-dom";


const Profile = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [mode, setMode] = useState("general")
    const cm = useCommon();
    const renewAuth = useRenewAuth();

    useEffect(() => {
        renewAuth.renew();
    }, []);

    useEffect(() => {
        const mode = searchParams.get("mode");
        setMode(mode || "general")
    }, [searchParams])

    const changeMode = (e, mode) => {
        setMode(mode)
        setSearchParams({})
    }


    return (
        <div
            className={"col-sm-12 col-md-8 mx-auto my-2 my-md-4  px-3 px-md-0"}
        >
            <div className="d-flex justify-content-end bg-light border rounded p-2 mb-4">
                <button className={(mode === "general" ? "btn-success" : "btn-outline-success") + " btn btn-sm rounded-0"} onClick={e => changeMode(e, "general")}>{cm?.translate("General Profile")}</button>
                <button className={(mode === "business" ? "btn-success" : "btn-outline-success") + " btn btn-sm rounded-0"} onClick={e => changeMode(e, "business")}>{cm?.translate("Business Profile")}</button>
            </div>

            {mode === "business" ? <BusinessProfile /> : <GeneralProfile />}

        </div>
    );
};
export default Profile;
