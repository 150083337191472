import { useEffect, useState } from "react";
import ChangePassword from "../modals/ChangePassword";
import { useApi } from "../../hooks/useApi"
import { useAuth } from "../../hooks/useAuth"
import { useCommon } from "../../hooks/useCommon";
import useAlert from "../../hooks/useAlert";
import { BsGrid, BsGrid3X2 } from "react-icons/bs";


const Settings = () => {
    const [userSettings, setUserSettings] = useState()
    const api = useApi();
    const auth = useAuth();
    const cm = useCommon();
    const alert = useAlert();


    const handleSetting = async (e) => {
        try {
            const { name, value } = e.target;

            setUserSettings({ ...userSettings, [name]: value === "true" ? false : true })
            const data = { [name]: value === "true" ? false : true }

            const res = await api.updateUserSettings(auth.authUser?.alias_id, data);

            if (res) {
                setUserSettings(res);
                auth.updateAuthUser({ ...auth.authUser, user_settings: res })
            }

            if (name === "browser_notification" && value === "false") {
                Notification.requestPermission().then(perm => console.log(perm))
            }

        } catch (err) {
            alert.show(err?.response?.data?.msg, "error");
        }
    }

    const handleAdListing = async (value) => {
        try {
            setUserSettings({ ...userSettings, ads_listing_style: value })
            const data = { ads_listing_style: value }

            const res = await api.updateUserSettings(auth.authUser?.alias_id, data);

            if (res) {
                setUserSettings(res);
                auth.updateAuthUser({ ...auth.authUser, user_settings: res })
            }

        } catch (err) {
            alert.show(err?.response?.data?.msg, "error");
        }
    }

    useEffect(() => {
        setUserSettings(auth.authUser?.user_settings)
    }, [auth.authUser]);

    // useEffect(() => {
    //     console.log(userSettings)
    // }, [userSettings]);
    return (
        userSettings && (
            <div
                className={"col-sm-12 col-md-6 mx-auto my-2 my-md-4  px-3 px-md-0"}
            >
                <h3>{cm?.translate("Settings")}</h3>
                <div className="row m-0 d-flex align-items-center mb-1 bg-light p-3 px-0">
                    <div className="col">
                        <span>{cm?.translate("Show phone number in your Ad.")}</span>
                    </div>
                    <div className="col d-flex justify-content-end pe-0">
                        <div className="form-check form-switch">
                            <input name="show_phone_number" className="form-check-input fs-4" type="checkbox" role="switch" onChange={(e) => handleSetting(e)} checked={userSettings?.show_phone_number} value={userSettings?.show_phone_number}></input>
                        </div>
                    </div>
                </div>
                <div className="row m-0 d-flex align-items-center mb-1 bg-light p-3 px-0">
                    <div className="col">
                        <span>{cm?.translate("Get update mail from Monozuna")}</span>
                    </div>
                    <div className="col d-flex justify-content-end pe-0">
                        <div className="form-check form-switch">
                            <input name="mail_notification" className="form-check-input fs-4" type="checkbox" role="switch" onChange={(e) => handleSetting(e)} checked={userSettings?.mail_notification} value={userSettings?.mail_notification}></input>
                        </div>
                    </div>
                </div>
                {/* <div className="row m-0 d-flex align-items-center mb-1 bg-light p-3 px-0">
                    <div className="col">
                        <span>{cm?.translate("Browser notification")}</span>
                    </div>
                    <div className="col d-flex justify-content-end pe-0">
                        <div className="form-check form-switch">
                            <input name="browser_notification" className="form-check-input fs-4" type="checkbox" role="switch" onChange={(e) => handleSetting(e)} checked={userSettings?.browser_notification} value={userSettings?.browser_notification}></input>
                        </div>
                    </div>
                </div> */}
                <div className="row m-0 d-flex align-items-center mb-1 bg-light p-3 px-0">
                    <div className="col">
                        <span>{cm?.translate("New Ad. mail notification")}</span>
                        <span className="font-size-8 d-block text-secondary">{cm?.translate("New Ad. created by the owner whom you follow")}</span>
                    </div>
                    <div className="col d-flex justify-content-end pe-0">
                        <div className="form-check form-switch">
                            <input name="new_ad_notification" className="form-check-input fs-4" type="checkbox" role="switch" onChange={(e) => handleSetting(e)} checked={userSettings?.new_ad_notification} value={userSettings?.new_ad_notification}></input>
                        </div>
                    </div>
                </div>
                {auth?.authUser?.user_business_profile?.is_approved &&
                    <div className="row m-0 d-flex align-items-center mb-1 bg-light p-3 px-0">
                        <div className="col">
                            <span>{cm?.translate("Comment notification")}</span>
                            <span className="font-size-8 d-block text-secondary">{cm?.translate("When someone comment to your post")}</span>
                        </div>
                        <div className="col d-flex justify-content-end pe-0">
                            <div className="form-check form-switch">
                                <input name="ad_comment_notification" className="form-check-input fs-4" type="checkbox" role="switch" onChange={(e) => handleSetting(e)} checked={userSettings?.ad_comment_notification} value={userSettings?.ad_comment_notification}></input>
                            </div>
                        </div>
                    </div>
                }


                <div className="row m-0 d-flex align-items-center mb-1 bg-light p-3 px-0">
                    <div className="col">
                        <span>{cm?.translate("Password")}</span>
                    </div>
                    <div className="col d-flex justify-content-end">
                        <ChangePassword />
                    </div>
                </div>

                <div className="row m-0 d-flex align-items-center mb-1 bg-light p-3 px-0">
                    <div className="col">
                        <span>{cm?.translate("Ad. Listing Style")}</span>
                    </div>
                    <div className="col d-flex justify-content-end pe-3">
                        <button name="ads_listing_style" className={(userSettings.ads_listing_style === "default" ? "btn-success" : "btn-white") + " btn rounded-0"} value="default" title="Grid" onClick={(e) => handleAdListing("default")}><BsGrid3X2 className="fs-4" /></button>
                        <button name="ads_listing_style" className={(userSettings.ads_listing_style !== "default" ? "btn-success" : "btn-white") + " btn ms-2 rounded-0"} value="thumb" title="Thumb" onClick={(e) => handleAdListing("thumb")}><BsGrid className="fs-4" /></button>

                    </div>
                </div>
            </div>)
    );
};
export default Settings;
