import { useEffect, useState } from "react";
import { useApi } from "../../hooks/useApi"
import { useAuth } from "../../hooks/useAuth"
import { useCommon } from "../../hooks/useCommon";
import useAlert from "../../hooks/useAlert";

const Disclaimer = () => {
    const [disclaimer, setDisclaimer] = useState({})
    const api = useApi();
    const auth = useAuth();
    const cm = useCommon();
    const alert = useAlert();


    const getDisclaimerFromApi = async () => {
        try {
            cm.showLoader(true);
            const res = await api.getStaticContent("disclaimer");
            if (res) {
                setDisclaimer(res);
                cm.showLoader(false);
            }
        } catch (err) {
            cm.showLoader(false);
            alert.show(err?.response?.data?.msg, "error");
        }
    }

    useEffect(() => {
        if (!auth.authUser?.user_settings?.disclaimer_agree) {
            getDisclaimerFromApi()
        } else {
            window.location = "/login"
        }
    }, []);

    const handleAgree = async () => {
        try {
            // setShowLoading({ show: true, title: "Updating.." });
            const res = await api.updateUserSettings(auth.authUser?.alias_id, { "disclaimer_agree": true });
            if (res) {
                auth.updateAuthUser({ ...auth.authUser, user_settings: res });
                window.location = "/mypage/profile";
            }
        } catch (err) {
            alert.show(err?.response?.data?.msg, "error");
        }
    }
    const handleDontAgree = async () => {
        // setShowLoading({ show: true, title: "Updating.." });
        auth.logout()
        window.location = "/login";
    }

    return (
        <div
            className={"col-sm-12 col-md-8 mx-auto my-2 my-md-4  px-3 px-md-0"}
        >
            <div className="row m-0 d-flex align-items-center mb-1 bg-light p-3 px-2 overflow-scroll" style={{ maxHeight: 600 }}>
                <p dangerouslySetInnerHTML={{ __html: disclaimer?.en }} style={{ textAlign: "justify" }}></p>
            </div>

            <div className="row m-0 d-flex align-items-center mb-1 bg-light p-5 px-0">
                <div className="col d-flex justify-content-center">
                    <button className="btn btn-success" onClick={() => handleAgree()}>Agree to the terms & condition</button>
                    <button className="btn btn-secondary ms-2" onClick={() => handleDontAgree()}>Don't Agree</button>
                </div>
            </div>
        </div>
    );
};
export default Disclaimer;
