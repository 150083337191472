import { Modal } from "react-bootstrap";
import { useState, useRef, useEffect } from "react";
import { useAuth } from "../../hooks/useAuth"
import { useCommon } from "../../hooks/useCommon";
import { BsFilter } from "react-icons/bs";
import { useSearchParams } from "react-router-dom";
import { decodeSearchParams } from "../../utils/utils";

const FilterSelection = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [show, setShow] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [selectedLocation, setSelectedLocation] = useState({});
  const [title, setTitle] = useState("Filter Ads.");
  const cm = useCommon();

  const handleClose = () => {
    // if (Object.keys(selectedCategory).length <= 0 && Object.keys(selectedLocation).length <= 0) {
    //   setSearchParams({})
    //   setTitle("Filter Ads.")
    // }
    setShow(false);
  };

  const handleShow = () => {
    setShow(true);
  };

  useEffect(() => {
    const params = decodeSearchParams(searchParams)

    if ("category" in params) {
      setSelectedCategory(cm?.categories?.find(c => c.name === params?.category))
    }
    if ("location" in params) {
      setSelectedLocation(cm?.locations?.find(l => l.name === params?.location))
    }
  }, [show])

  const handleCategorySelect = (e, cat) => {
    if (selectedCategory?.name !== cat?.name) {
      setSelectedCategory(cat)
    } else {
      setSelectedCategory({})
    }
  }

  const handleLocationSelect = (e, loc) => {
    if (selectedLocation?.name !== loc?.name) {
      setSelectedLocation(loc)
    } else {
      setSelectedLocation({})
    }
  }

  const renderCategories = () => {
    const uniq = (items) => [...new Set(items)];
    const uniqueGroups = uniq(cm?.categories.map((item) => item?.group?.name));
    return uniqueGroups?.map((g, ix) => {
      return <div key={"grou_cat" + ix} > <h6 className={"text-bg-warning bg-opacity-50 rounded p-2 " + (ix > 0 && " mt-3")}>{cm?.translate(g)}</h6>
        <div className="double-col">
          {cm?.categories && cm?.categories?.map(c => c?.group?.name === g && <button key={"cat_" + c.id} className={"btn btn-sm w-100 text-start rounded " + (c?.name === selectedCategory?.name ? "btn-success" : "")} onClick={e => handleCategorySelect(e, c)}>{cm?.translate(c?.name)} ({cm?.translate(c?.total_ads, cm?.language, true)})</button>)}
        </div>
      </div>
    })
  }

  const renderLocation = () => {
    return <>
      <h6 className={"text-bg-warning bg-opacity-50 rounded p-2 "}>{cm?.translate("Location")}</h6>
      <div className="double-col">
        {cm?.locations && cm?.locations?.map(l => <button key={"loc_" + l.id} className={"btn btn-sm w-100 text-start rounded " + (l?.name === selectedLocation?.name ? "btn-success" : "")} onClick={e => handleLocationSelect(e, l)}>{cm?.translate(l?.name)} ({cm?.translate(l?.total_ads, cm?.language, true)})</button>)}
      </div>
    </>
  }

  const doFilter = () => {
    let title = ""
    let sp = {}
    if (Object.keys(selectedCategory).length) {
      cm?.setSelectedCategory(selectedCategory)
      title = title + selectedCategory?.name + " "
      sp = { category: selectedCategory?.name }
    }

    if (Object.keys(selectedLocation).length) {
      cm?.setSelectedLocation(selectedLocation)
      sp = { ...sp, location: selectedLocation?.name }
      title = title + ((Object.keys(selectedCategory).length > 0 ? "& " : "") + selectedLocation?.name)
    }
    if (Object.keys(selectedCategory).length || Object.keys(selectedLocation).length) {
      setTitle(title)
      setSearchParams(sp)

    } else {
      setTitle("Filter Ads.")
      setSearchParams({})
    }

    setShow(false)
  }


  return (
    <>
      <button
        onClick={handleShow}
        className="align-self-stretch btn btn-outline-success rounded border-0 d-flex justify-content-center align-items-center"
      >
        <BsFilter className="fs-4" />
        <span className="d-inline-block ms-2">{cm?.translate(title)}</span>
      </button>
      <Modal size="lg" backdrop="static" scrollable="true" show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>{cm?.translate("Filter Ads.")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-3 px-2 overflow-scroll" style={{ maxHeight: "500px" }}>
          <div className="row gx-4 m-0">
            <div className="col-sm-12 col-md d-flex flex-column">
              {renderCategories()}
            </div>
            <div className="col-sm-12 col-md mt-4 mt-md-0">
              {renderLocation()}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-sm btn-success" onClick={doFilter}>{cm?.translate("Set Filter")}</button>
          <button className="btn btn-sm btn-secondary" onClick={handleClose}>{cm?.translate("Cancel")}</button>
        </Modal.Footer>
      </Modal >
    </>
  );
}
export default FilterSelection