import { useEffect, useState } from "react";

const useAudio = url => {
    const [audio, setAudio] = useState(new Audio());
    const [playing, setPlaying] = useState(false);

    const toggle = () => setPlaying(!playing);

    useEffect(() => {
        if (audio) {
            if (playing) {
                audio.play()
            } else {
                audio.pause()
            }
        } else {
            setAudio(new Audio())
        }
    }, [playing]);

    useEffect(() => {
        audio.addEventListener('ended', () => setPlaying(false));
        return () => {
            audio.removeEventListener('ended', () => setPlaying(false));
        };
    }, []);

    useEffect(() => {
        url && setAudio(new Audio(url))
    }, [url])

    return [playing, toggle];
};
export default useAudio;