import { useEffect, useState } from "react"
import { useApi } from "../hooks/useApi";
import useAlert from "../hooks/useAlert";
import { decodeSearchParams } from "../utils/utils";
import { useSearchParams } from "react-router-dom";

const StaticPage = () => {
    const [searchParams] = useSearchParams();
    const [content, setContent] = useState("");
    const api = useApi();
    const alert = useAlert();

    const getStaticContent = async (title) => {
        try {
            const res = await api.getStaticContent(title);
            if (res) {
                setContent(res);
            }
        } catch (err) {
            alert.show(err?.response?.data?.msg || err?.response?.data?.detail || err?.response?.statusText, "error");
        }
    }

    useEffect(() => {
        const params = decodeSearchParams(searchParams)
        getStaticContent(params?.title)
    }, [searchParams]);


    return <div className={"col-sm-12 col-md-9 mx-auto my-2 my-md-4 px-2 px-md-0"}>
        <div className="mt-3 border border-success border-opacity-25 rounded p-4">
            <p dangerouslySetInnerHTML={{ __html: content?.en }}>
            </p>
        </div>
    </div>
}
export default StaticPage