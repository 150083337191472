import { useEffect, useState } from "react";
import { useCommon } from "../../hooks/useCommon"
import { useApi } from "../../hooks/useApi"
import useAlert from "../../hooks/useAlert"
import moment from "moment";
import { LiaCoinsSolid } from "react-icons/lia";
import { useAuth } from "../../hooks/useAuth";
import { Modal } from "react-bootstrap";

const CoinTransaction = () => {
    const [show, setShow] = useState(false);
    const [transactions, setTransactions] = useState();

    const cm = useCommon();
    const api = useApi();
    const alert = useAlert();
    const auth = useAuth();


    const handleClose = () => {
        setShow(false)
    }

    const handleGetCoinTransactions = async () => {
        try {
            cm.showLoader(true, "Loading..");
            const res = await api.getCoinTransactions();
            if (res) {
                setTransactions(res)
            }
            cm.showLoader(false);
        } catch (err) {
            cm.showLoader(false);
            alert.show(err?.response?.data?.msg || err, "error");
        }
    };


    const renderTrans = () => {
        return transactions && transactions?.results?.map((t, ix) => {
            return <div key={"trax_" + ix} className="row m-0 bg-light mb-1 font-size-11 py-1 d-flex align-items-center">
                <div className="col text-start  font-size-10">{moment(t?.transaction_time).format("YYYY-MM-DD HH:mm")}</div>
                <div className="col text-start">{t?.purpose}</div>
                <div className="col text-end">{t?.quantity} {cm?.translate("coins")}</div>
            </div>
        })
    }

    useEffect(() => {
        handleGetCoinTransactions()
    }, [])

    const handleShow = () => {
        setShow(true);
    };

    return <>
        <button className="btn rounded px-1 py-0" title={cm?.translate("Coins")} onClick={handleShow}><LiaCoinsSolid className="fs-4" /> {cm?.translate(auth?.authUser?.coins || 0)}</button>
        <Modal scrollable={true} show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title><LiaCoinsSolid className="fs-2" /> {cm?.translate("Coins")}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-2">
                <div className="row m-0 bg-light mb-1 font-size-11 py-1 d-flex align-items-center fw-bold">
                    <div className="col text-start">{cm?.translate("Balance")}</div>
                    <div className="col text-end">{auth?.authUser?.coins} {cm?.translate("coins")}</div>
                </div>
                {renderTrans()}
            </Modal.Body>
        </Modal >
    </>
}

export default CoinTransaction