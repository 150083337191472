import { createContext, useContext } from "react";
import axios from "axios";
import { useAuth } from "./useAuth";
import { useCommon } from "./useCommon";

const ApiContext = createContext();

export const ApiProvider = ({ children }) => {

    const auth = useAuth();
    const cm = useCommon();

    const monozunaApi = axios.create({
        baseURL: process.env.REACT_APP_API_SERVER + "/api/v1",
        headers: {
            "Content-Type": "application/json",
        },
        timeout: 1000 * 60 * 2,
    });


    monozunaApi.interceptors.request.use(
        async (config) => {
            if (auth.authToken) {
                config.headers = Object.assign({
                    Authorization: auth.authToken
                }, config.headers)
            }
            return config
        }
    );

    const hexToStr = (str1) => {
        var hex = str1.toString();
        var str = '';
        for (var n = 0; n < hex.length; n += 2) {
            str += String.fromCharCode(parseInt(hex.substr(n, 2), 16));
        }
        return str;
    }


    monozunaApi.interceptors.response.use(
        async (response) => {
            cm?.setError("")
            return response;
        },
        (error) => {
            if (error?.response?.data?.msg === "Token Expired!") {
                auth.logout()
                window.location = "/login"
            }
            else if (error?.code === "ERR_NETWORK") {
                cm?.setError("Internet connection is not available!")
            } else if (error?.code === "ERR_BAD_RESPONSE") {
                cm?.setError(error?.response?.data || error?.response?.statusText || error?.msg || "Server errror! Try again.")
            }
            else {
                throw error;
            }

        }
    );

    const loginAuth = async (email_or_phone, password) => {
        try {

            const cred = { email_or_phone: email_or_phone, password: password }
            const response = await monozunaApi.post("/login/", cred);
            return response?.data;
        } catch (err) {
            throw err;
        }
    };


    const renewAuth = async (email_or_phone) => {
        try {
            const data = { email_or_phone: email_or_phone }
            const response = await monozunaApi.post("/renew-auth/", data);
            return response?.data;
        } catch (err) {
            throw err;
        }
    };

    const register = async (cred) => {
        try {

            const response = await monozunaApi.post("/register/", cred);
            return response?.data;
        } catch (err) {
            return err
        }
    };

    const verifyCode = async (cred) => {
        try {

            const response = await monozunaApi.post("/register/verify-code/", cred);
            return response?.data;
        } catch (err) {
            throw err;
        }
    };

    const getDictionary = async () => {
        try {
            const response = await monozunaApi.get("/dictionary/");
            return response?.data?.results ? response?.data?.results : response?.data;
        } catch (err) {
            throw err
        }
    };

    const getLocations = async () => {
        try {

            const response = await monozunaApi.get("/locations/");
            return response?.data?.results ? response?.data?.results : response?.data;
        } catch (err) {
            throw err
        }
    };

    const getCategoryGroups = async () => {
        try {

            const response = await monozunaApi.get("/category-groups/");
            return response?.data?.results ? response?.data?.results : response?.data;
        } catch (err) {
            throw err;
        }
    };

    const getCategories = async () => {
        try {

            const response = await monozunaApi.get("/categories/");
            return response?.data?.results ? response?.data?.results : response?.data;
        } catch (err) {
            throw err;
        }
    };

    const getAds = async (params) => {
        try {
            const response = await monozunaApi.get("/ads/", { params });
            return response?.data;
        } catch (err) {
            throw err;
        }
    };

    const getAdDetail = async (aliasId) => {
        try {
            const response = await monozunaApi.get(`/ads/${aliasId}/`);
            return response?.data;
        } catch (err) {
            throw err;
        }
    };

    const updateAd = async (aliasId, data) => {
        try {
            const response = await monozunaApi.put(`/ads/${aliasId}/`, data);
            return response?.data;
        } catch (err) {
            throw err;
        }
    };


    const deleteAd = async (aliasId) => {
        try {
            const response = await monozunaApi.delete(`/ads/${aliasId}/`);
            return response?.data;
        } catch (err) {
            throw err;
        }
    };

    const toggleAdApproval = async (aliasId) => {
        try {
            const response = await monozunaApi.post(`/ads/${aliasId}/toggle-approval/`);
            return response?.data;
        } catch (err) {
            throw err;
        }
    };

    const getMembers = async (params) => {
        try {
            const response = await monozunaApi.get("/users/", { params });
            return response?.data;
        } catch (err) {
            throw err;
        }
    };

    const createOrder = async (aliasId, data) => {
        try {
            const response = await monozunaApi.post(`/ads/${aliasId}/create-order/`, data);
            return response?.data;
        } catch (err) {
            throw err;
        }
    };

    const getOrders = async (params) => {
        try {
            const response = await monozunaApi.get(`/orders/`, { params });
            return response?.data;
        } catch (err) {
            throw err;
        }
    };


    const updateOrder = async (order_no, data) => {
        try {
            const response = await monozunaApi.put(`/orders/${order_no}/`, data);
            return response?.data;
        } catch (err) {
            throw err;
        }
    };

    const updatePostLike = async (aliasId) => {
        try {
            const response = await monozunaApi.post(`/ads/${aliasId}/like/`);
            return response?.data;
        } catch (err) {
            throw err;
        }
    };

    const getCoinTransactions = async () => {
        try {
            const response = await monozunaApi.get(`/coins/`);
            return response?.data;
        } catch (err) {
            throw err;
        }
    };

    const createCoinTransactions = async (data) => {
        try {
            const response = await monozunaApi.post(`/coins/`, data);
            return response?.data;
        } catch (err) {
            throw err;
        }
    };

    const getUserDetail = async (aliasId) => {
        try {
            const response = await monozunaApi.get(`/users/${aliasId}/`);
            return response?.data;
        } catch (err) {
            throw err;
        }
    };


    const updateUserProfile = async (user_alias_id, data) => {
        try {

            const response = await monozunaApi.put(`/users-profile/${user_alias_id}/`, data);
            return response?.data;
        } catch (err) {
            throw err;
        }
    };

    const createBusinessProfile = async (data) => {
        try {

            const response = await monozunaApi.post(`/users-business-profile/`, data);
            return response?.data;
        } catch (err) {
            throw err;
        }
    };

    const updateBusinessProfile = async (user_alias_id, data) => {
        try {

            const response = await monozunaApi.patch(`/users-business-profile/${user_alias_id}/`, data);
            return response?.data;
        } catch (err) {
            throw err;
        }
    };

    const updateUserSettings = async (user_alias_id, data) => {
        try {

            const response = await monozunaApi.put(`/users-settings/${user_alias_id}/`, data);
            return response?.data;
        } catch (err) {
            throw err;
        }
    };

    const changePassword = async (data) => {
        try {

            const response = await monozunaApi.put("/users/change-password/", data);
            return response?.data;
        } catch (err) {
            throw err;
        }
    };

    const recoverPassword = async (data) => {
        try {

            const response = await monozunaApi.post("/users/recover-password/", data);
            return response?.data;
        } catch (err) {
            throw err;
        }
    };

    const renewPassword = async (data) => {
        try {

            const response = await monozunaApi.post("/users/renew-password/", data);
            return response?.data;
        } catch (err) {
            throw err;
        }
    };

    const suspendMember = async (alias_id) => {
        try {

            const response = await monozunaApi.put(`/users/${alias_id}/suspend/`);
            return response?.data;
        } catch (err) {
            throw err;
        }
    };

    const deleteMember = async (alias_id) => {
        try {
            const response = await monozunaApi.delete(`/users/${alias_id}/`);
            return response?.data;
        } catch (err) {
            throw err;
        }
    };


    const activateMember = async (alias_id) => {
        try {

            const response = await monozunaApi.put(`/users/${alias_id}/activate/`);
            return response?.data;
        } catch (err) {
            throw err;
        }
    };

    const getSimilarAds = async (aliasId) => {
        try {

            const response = await monozunaApi.get(`/ads/${aliasId}/similar/`, {});
            return response?.data;
        } catch (err) {
            throw err;
        }
    };

    const getUserAds = async (aliasId, params) => {
        try {
            const response = await monozunaApi.get(`/users/${aliasId}/ads/`, { params });
            return response?.data;
        } catch (err) {
            throw err;
        }
    };

    const createAd = async (data) => {
        try {

            const response = await monozunaApi.post(`/ads/`, data);
            return response?.data;
        } catch (err) {
            throw err;
        }
    };

    const getAdComments = async (adAliasId) => {
        try {

            const response = await monozunaApi.get(`/ads/${adAliasId}/comments/`);
            return response?.data;
        } catch (err) {
            throw err;
        }
    };

    const postAdComment = async (data) => {
        try {

            const response = await monozunaApi.post(`/comments/`, data);
            return response?.data;
        } catch (err) {
            throw err;
        }
    };

    const deleteAdComment = async (id) => {
        try {

            const response = await monozunaApi.delete(`/comments/${id}/`);
            return response?.data;
        } catch (err) {
            throw err;
        }
    };

    const getUserFollowers = async (aliasId, params) => {
        try {

            const response = await monozunaApi.get(`/users/${aliasId}/followers/`, { params });
            return response?.data;
        } catch (err) {
            throw err;
        }
    };

    const getUserFollowings = async (aliasId, params) => {
        try {

            const response = await monozunaApi.get(`/users/${aliasId}/followings/`, { params });
            return response?.data;
        } catch (err) {
            throw err;
        }
    };

    const updateUserFollower = async (aliasId) => {
        try {

            const response = await monozunaApi.post(`/users/${aliasId}/follow/`);
            return response?.data;
        } catch (err) {
            throw err;
        }
    };

    const getPrivacyTypes = async () => {
        try {

            const response = await monozunaApi.get("/privacy-types/");
            return response?.data?.results ? response?.data?.results : response?.data;
        } catch (err) {
            throw err;
        }
    };

    const getStaticContent = async (title) => {
        try {

            const response = await monozunaApi.get(`/static-contents/${title}/`);
            return response?.data;
        } catch (err) {
            throw err;
        }
    };

    const getInquiries = async () => {
        try {
            const response = await monozunaApi.get(`/inquiries/`);
            return response?.data;
        } catch (err) {
            throw err;
        }
    };

    const getInquiry = async (id) => {
        try {
            const response = await monozunaApi.get(`/inquiries/${id}/`);
            return response?.data;
        } catch (err) {
            throw err;
        }
    };

    const sendReply = async (data) => {
        try {
            const response = await monozunaApi.post(`/inquiries/reply/`, data);
            return response?.data;
        } catch (err) {
            throw err;
        }
    };


    const createInquiryConversation = async (data) => {
        try {

            const response = await monozunaApi.post(`/inquiries/`, data);
            return response?.data;
        } catch (err) {
            throw err;
        }
    };

    const createOrderable = async (data) => {
        try {
            const response = await monozunaApi.post(`/orderable/`, data);
            return response?.data;
        } catch (err) {
            throw err;
        }
    };

    const updateOrderable = async (id, data) => {
        try {
            const response = await monozunaApi.put(`/orderable/${id}/`, data);
            return response?.data;
        } catch (err) {
            throw err;
        }
    };


    const deleteOrderable = async (aliasId) => {
        try {
            const response = await monozunaApi.delete(`/ads/${aliasId}/delete-orderable/`);
            return response?.data;
        } catch (err) {
            throw err;
        }
    };

    const getAudios = async () => {
        try {
            const response = await monozunaApi.get(`/audios/`);
            return response?.data;
        } catch (err) {
            throw err;
        }
    };

    const getShorts = async () => {
        try {
            const response = await monozunaApi.get(`/shorts/`);
            return response?.data;
        } catch (err) {
            throw err;
        }
    };

    const createShorts = async (data) => {
        try {
            const response = await monozunaApi.post(`/shorts/`, data);
            return response?.data;
        } catch (err) {
            throw err;
        }
    };
    const updateShorts = async (id, data) => {
        try {
            const response = await monozunaApi.put(`/shorts/${id}/`, data);
            return response?.data;
        } catch (err) {
            throw err;
        }
    };

    const deleteShorts = async (aliasId) => {
        try {
            const response = await monozunaApi.delete(`/ads/${aliasId}/delete-shorts/`);
            return response?.data;
        } catch (err) {
            throw err;
        }
    };

    const deleteShortsByAdmin = async (id) => {
        try {
            const response = await monozunaApi.delete(`/shorts/${id}/`);
            return response?.data;
        } catch (err) {
            throw err;
        }
    };

    const applyToJob = async (data) => {
        try {
            const response = await monozunaApi.post(`/job-applications/`, data);
            return response?.data;
        } catch (err) {
            throw err;
        }
    };


    const getJobApplicationsAds = async () => {
        try {
            const response = await monozunaApi.get(`/job-applications/ads/`);
            return response?.data;
        } catch (err) {
            throw err;
        }
    };


    const getJobApplications = async (params) => {
        try {
            const response = await monozunaApi.get(`/job-applications/`, { params });
            return response?.data;
        } catch (err) {
            throw err;
        }
    };

    const sendMailSignal = async () => {
        try {
            const response = await monozunaApi.post(`/send-mail-signal/`);
            return response?.data;
        } catch (err) {
            throw err;
        }
    };

    const sendAdminMail = async (data) => {
        try {
            const response = await monozunaApi.post(`/send-admin-mail/`, data);
            return response?.data;
        } catch (err) {
            throw err;
        }
    };

    const sendAdminSms = async (data) => {
        try {
            const response = await monozunaApi.post(`/send-admin-sms/`, data);
            return response?.data;
        } catch (err) {
            throw err;
        }
    };


    const sendMessage = async (data) => {
        try {
            const response = await monozunaApi.post(`/send-message/`, data);
            return response?.data;
        } catch (err) {
            throw err;
        }
    };

    const getHealth = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_SERVER + "/");
            return true;
        } catch (err) {
            console.log(err)
        }
    };


    const value = {
        loginAuth,
        renewAuth,
        register,
        getMembers,
        suspendMember,
        deleteMember,
        activateMember,
        getCoinTransactions,
        createCoinTransactions,
        getAds,
        getAdDetail,
        getAdComments,
        getCategories,
        getCategoryGroups,
        getDictionary,
        getInquiries,
        getInquiry,
        sendReply,
        getLocations,
        getPrivacyTypes,
        getSimilarAds,
        getStaticContent,
        getUserAds,
        getUserDetail,
        getUserFollowers,
        getUserFollowings,
        updateAd,
        updatePostLike,
        toggleAdApproval,
        updateUserFollower,
        updateUserProfile,
        updateUserSettings,
        createBusinessProfile,
        updateBusinessProfile,
        createAd,
        createInquiryConversation,
        deleteAd,
        deleteAdComment,
        postAdComment,
        recoverPassword,
        changePassword,
        verifyCode,
        renewPassword,
        createOrder,
        updateOrder,
        getOrders,
        getAudios,
        getShorts,
        createShorts,
        updateShorts,
        deleteShorts,
        deleteShortsByAdmin,
        createOrderable,
        updateOrderable,
        deleteOrderable,
        applyToJob,
        getJobApplicationsAds,
        getJobApplications,
        sendMailSignal,
        sendAdminMail,
        sendAdminSms,
        sendMessage,
        getHealth
    };
    return <ApiContext.Provider value={value}>{children}</ApiContext.Provider>;

}

export const useApi = () => {
    return useContext(ApiContext);
};