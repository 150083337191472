import { Outlet } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";

const Layout = () => {
    return (
        <>
            <Header />
            <div style={{ maxWidth: 1440, margin: "0 auto" }}>
                <Outlet />
                <Footer />
            </div>
        </>
    );
};
export default Layout;