import { useEffect, useState } from "react";
import useAlert from "../../hooks/useAlert";
import { useApi } from "../../hooks/useApi";
import { useAuth } from "../../hooks/useAuth";
import { useCommon } from "../../hooks/useCommon"
import ImageUploader from "../ImageUploader"
import PreviewMediaFile from "../PreviewMediaFile";
import useRenewAuth from "../../hooks/useRenewAuth";
import { LiaCoinsSolid } from "react-icons/lia";
import CoinTransaction from "../modals/CoinTransaction";
import BuyCoins from "../modals/BuyCoins";

const BusinessProfile = () => {
    const [disableSaving, setDisableSaving] = useState(false)
    const [profile, setProfile] = useState({})
    const [activeBusinessProfile, setActiveBusinessProfile] = useState(false)

    const cm = useCommon();
    const api = useApi();
    const alert = useAlert();
    const auth = useAuth();
    const renewAuth = useRenewAuth()

    useEffect(() => {
        setProfile({
            alias_id: auth?.authUser?.alias_id,
            email_or_phone: auth?.authUser?.email_or_phone,
            is_superuser: auth?.authUser?.is_superuser,
            is_active: auth?.authUser?.is_active,
            user_business_profile: auth?.authUser?.user_business_profile || {},
        })
    }, [])

    useEffect(() => {
        if (profile?.user_business_profile && Object.keys(profile?.user_business_profile).length) {
            setActiveBusinessProfile(true)
        } else {
            setActiveBusinessProfile(false)
        }
    }, [profile])


    const handleUploadLogoImage = (e) => {
        let up = { ...profile?.user_business_profile }
        up["changed_company_logo"] = e[0]?.base64;
        setProfile({ ...profile, user_business_profile: up })
    }
    const handleUploadIdImage = (e) => {
        let up = { ...profile?.user_business_profile }
        up["changed_identification_doc"] = e[0]?.base64;
        setProfile({ ...profile, user_business_profile: up })
    }

    const handleFormInput = (e) => {
        const { name, value } = e.target;

        setProfile((currVal) => {

            let up = { ...currVal?.user_business_profile }

            if (name === "location") {
                up = {
                    ...up,
                    [name]: cm?.locations?.find((l) => l.id === parseInt(value)),
                };
            }
            else {
                up = { ...up, [name]: value };
            }

            const updateValue = { ...currVal, user_business_profile: up };

            if ("company_name" in updateValue?.user_business_profile && "location" in updateValue?.user_business_profile && "company_phone" in updateValue?.user_business_profile && "address" in updateValue?.user_business_profile) {
                setDisableSaving(false)
            } else {
                setDisableSaving(true)
            }
            return updateValue;
        });

    };

    const renderLocation = () => {
        return cm?.locations?.map((l) =>
            <option key={"loc_" + l.id} value={l.id}>{l.name}</option>
        )
    }

    const removeCompanyLogo = () => {
        let up = { ...profile?.user_business_profile, company_logo: "", deleted_company_logo: profile?.user_business_profile?.company_logo }
        delete up?.changed_company_logo;
        setProfile({ ...profile, user_business_profile: up });
    };

    const removeIdentificationDoc = () => {
        let up = { ...profile?.user_business_profile, identification_doc: "", deleted_identification_doc: profile?.user_business_profile?.identification_doc }
        delete up?.changed_identification_doc;
        setProfile({ ...profile, user_business_profile: up });
    };

    const handleSave = async (vProfile) => {
        try {

            cm?.showLoader(true, "Saving..")

            let res = {}
            if ("user_business_profile" in auth?.authUser && Object.keys(auth?.authUser?.user_business_profile).length) {
                res = await api.updateBusinessProfile(auth.authUser?.alias_id, vProfile?.user_business_profile || profile?.user_business_profile);
            } else {
                res = await api.createBusinessProfile(vProfile?.user_business_profile || profile?.user_business_profile);
            }

            if (res) {
                cm?.setAdsCache({});
                setProfile({ ...profile, user_business_profile: res })
                renewAuth.renew();
                cm?.showLoader(false);
                alert.toast(cm?.translate("Business informtion was saved"), "success")

                return true;
            }

        } catch (err) {
            cm?.showLoader(false)
            alert.show(err?.response?.data?.msg || err?.msg || err, "error")
            return false
        }
    };


    const activateBusinessProfile = (e) => {
        const ubp = { ...profile?.user_business_profile, is_active: true }
        const vProfile = { ...profile, user_business_profile: ubp }
        setProfile(vProfile)
    }

    const deactivateBusinessProfile = (e) => {
        const ubp = { ...profile?.user_business_profile, is_active: false }
        const vProfile = { ...profile, user_business_profile: ubp }
        setProfile(vProfile)
    }

    const profileStatus = () => {
        if (profile?.user_business_profile?.is_approved && profile?.user_business_profile?.is_active) {
            return <button className="btn btn-sm btn-warning rounded-pill px-3" onClick={e => deactivateBusinessProfile(e)}>{cm?.translate("Active")}</button>
        } else if ("is_approved" in profile?.user_business_profile && !profile?.user_business_profile?.is_approved) {
            return <span className="text-danger rounded-pill px-3 font-size-11">{cm?.translate("Under review by admin. You will be informed when it is approved.")}</span>
        } else if ("is_active" in profile?.user_business_profile && !profile?.user_business_profile?.is_active) {
            return <div><button className="btn btn-sm btn-secondary rounded-pill px-3" onClick={e => activateBusinessProfile(e)}>{cm?.translate("Inactive")}</button><span className="text-secondary font-size-9 ms-2">{cm.translate("Click to active")}</span></div>
        } else {
            return <span>{cm?.translate("Update your business information")}</span>
        }
    }

    return <div className="position-relative">
        {activeBusinessProfile && <>
            <div className="row m-0 d-flex align-items-center mb-1 bg-light px-0">
                <div className="col-sm-12 col-md-3 position-relative py-3">
                    <a href="/docs?title=faq&keyword=coin" className="text-decoration-none link-dark">{cm?.translate("Coins")}</a>
                </div>
                <div className="col">
                    <CoinTransaction />
                    <BuyCoins />
                </div>
            </div>
            <div className="row m-0 d-flex align-items-center mb-1 bg-light px-0">
                <div className="col-sm-12 col-md-3 position-relative py-3">
                    <span>{cm?.translate("Logo")}</span>
                </div>
                <div className="col d-flex justify-content-start align-items-center">
                    {(profile?.user_business_profile?.changed_company_logo || profile?.user_business_profile?.company_logo) && <PreviewMediaFile
                        src={
                            profile?.user_business_profile?.changed_company_logo || profile?.user_business_profile?.company_logo
                        }
                        removeFromPreview={removeCompanyLogo}
                    />}
                    <div className="ms-2 d-flex">
                        <ImageUploader
                            setNewFiles={handleUploadLogoImage}
                            label={profile?.user_business_profile?.company_logo ? cm?.translate("Change Logo") : cm?.translate("Upload Logo")}
                            multipleUpload="false"
                            width="auto"
                            clearPreview={true}
                        />
                    </div>
                </div>
            </div>

            <div className="row m-0 d-flex align-items-center mb-1 bg-light px-0">
                <div className="col-sm-12 col-md-3 position-relative py-3">
                    <span>{cm?.translate("Company Name")}</span>
                    <span className="font-size-8 text-secondary position-absolute end-0 bottom-0 me-1">{cm?.translate("Required")}</span>
                </div>
                <div className="col">
                    <input
                        type="text"
                        name="company_name"
                        className="form-control"
                        value={profile?.user_business_profile?.company_name || ""}
                        onChange={handleFormInput}
                        required={true}
                    />
                </div>
            </div>


            <div className="row m-0 d-flex align-items-center mb-1 bg-light px-0">
                <div className="col-sm-12 col-md-3 position-relative py-3">
                    <span>{cm?.translate("Address")}</span>
                    <span className="font-size-8 text-secondary position-absolute end-0 bottom-0 me-1">{cm?.translate("Required")}</span>
                </div>
                <div className="col">
                    <textarea
                        type="text"
                        rows={2}
                        name="address"
                        value={profile?.user_business_profile?.address || ""}
                        className="form-control"
                        onChange={handleFormInput}
                    ></textarea>
                </div>
            </div>
            <div className="row m-0 d-flex align-items-center mb-1 bg-light px-0">
                <div className="col-sm-12 col-md-3 position-relative py-3">
                    <span>{cm?.translate("Post Code")}</span>
                </div>
                <div className="col">
                    <input
                        type="text"
                        name="post_code"
                        className="form-control"
                        value={profile?.user_business_profile?.post_code || ""}
                        onChange={handleFormInput}
                        required={true}
                    />
                </div>
            </div>

            <div className="row m-0 d-flex align-items-center mb-1 bg-light px-0">
                <div className="col-sm-12 col-md-3 position-relative py-3">
                    <span>{cm?.translate("Location")}</span>
                    <span className="font-size-8 text-secondary position-absolute end-0 bottom-0 me-1">{cm?.translate("Required")}</span>
                </div>
                <div className="col">
                    <select
                        name="location"
                        className="form-select"
                        value={profile?.user_business_profile?.location?.id || ""}
                        onChange={handleFormInput}
                    >
                        <option value="">{cm?.translate("Select Location")}</option>
                        {renderLocation()}
                    </select>
                </div>
            </div>
            <div className="row m-0 d-flex align-items-center mb-1 bg-light px-0">
                <div className="col-sm-12 col-md-3 position-relative py-3">
                    <span>{cm?.translate("Email")}</span>
                </div>
                <div className="col">
                    <input
                        type="text"
                        name="company_email"
                        className="form-control"
                        value={profile?.user_business_profile?.company_email || ""}
                        onChange={handleFormInput}
                    />
                </div>
            </div>

            <div className="row m-0 d-flex align-items-center mb-1 bg-light px-0">
                <div className="col-sm-12 col-md-3 position-relative py-3">
                    <span>{cm?.translate("Phone")}</span>
                    <span className="font-size-8 text-secondary position-absolute end-0 bottom-0 me-1">{cm?.translate("Required")}</span>
                </div>
                <div className="col">
                    <input
                        type="text"
                        name="company_phone"
                        className="form-control"
                        value={profile?.user_business_profile?.company_phone || ""}
                        onChange={handleFormInput}
                        required={true}
                    />
                </div>
            </div>
            <div className="row m-0 d-flex align-items-center mb-1 bg-light px-0">
                <div className="col-sm-12 col-md-3 position-relative py-3">
                    <span>{cm?.translate("Web Link")}</span>
                </div>
                <div className="col">
                    <input
                        type="text"
                        name="web_link"
                        className="form-control"
                        value={profile?.user_business_profile?.web_link || ""}
                        onChange={handleFormInput}
                    />
                </div>
            </div>
            <div className="row m-0 d-flex align-items-center mb-1 bg-light px-0">
                <div className="col-sm-12 col-md-3 position-relative py-3">
                    <a href="/docs?title=faq&keyword=identification" className="text-decoration-none link-dark">{cm?.translate("Identification Document")}</a>
                    <span className="font-size-8 text-secondary position-absolute end-0 bottom-0 me-1">{cm?.translate("Required")}</span>
                </div>
                <div className="col d-flex justify-content-start align-items-center">
                    {(profile?.user_business_profile?.changed_identification_doc || profile?.user_business_profile?.identification_doc) && <PreviewMediaFile
                        src={
                            profile?.user_business_profile?.changed_identification_doc || profile?.user_business_profile?.identification_doc
                        }
                        removeFromPreview={removeIdentificationDoc}
                    />}
                    <div className="ms-2 d-flex">
                        <ImageUploader
                            setNewFiles={handleUploadIdImage}
                            label={profile?.user_business_profile?.identification_doc ? cm?.translate("Change Image") : cm?.translate("Upload Image")}
                            multipleUpload="false"
                            width="auto"
                            clearPreview={true}
                        />
                    </div>
                </div>
            </div>
            <div className="row m-0 d-flex align-items-center mb-1 bg-light px-0">
                <div className="col-sm-12 col-md-3 position-relative py-3">
                    <span>{cm?.translate("Status")}</span>
                </div>
                <div className="col d-flex justify-content-start align-items-center">
                    {profileStatus()}
                </div>
            </div>



            <div className="row m-0 d-flex align-items-center mb-1 bg-light p-3 px-0">
                <div className="col d-flex justify-content-center">
                    <button className="btn btn-sm btn-success" disabled={disableSaving} onClick={handleSave}>{cm?.translate("Save Changes")}</button>
                </div>
            </div>
        </>
        }

        {!activeBusinessProfile && <div className="bg-light  d-flex justify-content-center align-items-center" style={{ minHeight: 200 }}>
            <div className="d-flex flex-column justify-content-center align-items-center">
                <h5>{cm?.translate("Want to active Business Account?")}</h5>
                <button className="btn btn-success mt-3" onClick={e => setActiveBusinessProfile(true)}>{cm?.translate("Active")}</button>
            </div>
        </div>}

    </div>

}
export default BusinessProfile