import { useEffect, useRef, useState } from "react"
import Comment from "./Comment";
import { useApi } from "../hooks/useApi";
import { useAuth } from "../hooks/useAuth";
import { useCommon } from "../hooks/useCommon";
import useAlert from "../hooks/useAlert";

const Comments = ({ post, setPost }) => {
    const [comment, setComment] = useState();
    const [comments, setComments] = useState([]);
    const api = useApi();
    const auth = useAuth();
    const cm = useCommon();
    const alert = useAlert();

    const commentRef = useRef()


    const moveToComment = () => {
        const hash = window.location.hash.slice(1);
        if (hash === "comments") {
            window.scrollTo({ behavior: 'smooth', top: commentRef?.current?.offsetTop })
        }
    }

    const getAdCommentsFromApi = async () => {
        try {
            const res = await api.getAdComments(post?.alias_id);
            setComments(res);
        } catch (err) {
            alert.show(err, "error")
        }
    };

    useEffect(() => {
        post && getAdCommentsFromApi();
    }, [post]);


    const handleCommentInput = (e) => {
        setComment(e.target.value);
    }

    const postComment = async () => {
        if (!auth?.authUser) {
            window.location = "/login";
            return;
        }
        try {
            const res = await api.postAdComment({ comment: comment.replaceAll("\n", "<br />"), post_alias_id: post?.alias_id });
            if (res) {
                const cms = [res, ...comments?.results];
                const newCms = { ...comments, results: cms }
                setComments(newCms);
                cm?.setRefreshAdInMyAds({ alias_id: post?.alias_id })
                cm.showLoader(false)
            }
        } catch (err) {
            if (err?.response?.status === 401) {
                alert.show("Login is required.", "info")
            } else {
                alert.show(err?.response?.data?.msg, "error")
            }

        } finally {
            setComment("");
        }

    }

    const deleteComment = async (id) => {
        try {
            const res = await api.deleteAdComment(id);
            if (res?.msg) {
                const cms = comments?.results.filter((i) => {
                    return i?.id !== id;
                });
                setComments({ ...comments, results: cms })
                cm?.setRefreshAdInMyAds({ alias_id: post?.alias_id })
            }
        } catch (err) {
            alert.show(err, "error")
        }
    }

    const renderComments = () => {
        return comments?.results && comments?.results.map(c =>
            <Comment comment={c} key={"cmt_" + c.id} deleteComment={deleteComment} />
        )
    }

    return <div ref={commentRef} className="p-3 border-top overflow-scroll" style={{ maxHeight: 400 }}>
        <h5 className="fw-semibold mb-3">{cm?.translate("Comments")}</h5>
        <div className="d-flex">
            <textarea name="comment" value={comment} className="form-control" onChange={handleCommentInput} placeholder="Write your thought"></textarea>
            <button className="btn btn-sm btn-dark ms-2 text-nowrap" disabled={!comment} onClick={postComment}>{cm?.translate("Comment")}</button>
        </div>
        <div className="mt-4 mb-4 d-flex flex-column">
            {renderComments()}
            {comments?.next > 0 && <button className="btn btn-sm mt-3">{cm?.translate("Show More")}</button>}
        </div>
        {moveToComment()}
    </div>
}
export default Comments;