import React from "react";
import { BsFillPersonLinesFill, BsPostcard } from "react-icons/bs";
import { BsFillGearFill } from "react-icons/bs";
import { BiSolidUserCircle } from "react-icons/bi";
import { TbMessageQuestion } from "react-icons/tb";
import { useAuth } from "../hooks/useAuth";
import { useCommon } from "../hooks/useCommon";
import { TfiReceipt } from "react-icons/tfi";
import { GrDocumentText } from "react-icons/gr";
import { FaUserShield } from "react-icons/fa";

function MyPageMenu() {
    const auth = useAuth()
    const cm = useCommon()

    const toIndex = () => {
        window.location = "/";
    };

    const toProfile = () => {
        window.location = "/mypage/profile/";
    };
    const toAds = () => {
        window.location = "/mypage/ads/";
    };
    const toOrders = () => {
        window.location = "/mypage/orders/";
    };
    const toJobApplication = () => {
        window.location = "/mypage/job-applications/";
    };
    const toSettings = () => {
        window.location = "/mypage/settings/";
    };

    const toAdmin = () => {
        window.location = "/admin";
    };

    const toInquiries = () => {
        window.location = "/mypage/inquiries/";
    };

    const logout = () => {
        auth.logout();
        window.location = "/login";
    }

    const getRenderProfileImage = () => {
        if (auth.authUser && auth.authUser?.user_profile?.profile_image) {
            return <img className="bg-light rounded-2 object-fit-cover" src={process.env.REACT_APP_MEDIA_URL + auth.authUser?.user_profile?.profile_image} alt="user" style={{ height: 42, width: 42 }} />
        } else {
            return <BiSolidUserCircle className="text-secondary" style={{ fontSize: 42 }} />
        }
    }

    return (
        <div
            className="offcanvas offcanvas-end"
            tabIndex="-1"
            id="offcanvasMypageMenu"
            aria-labelledby="offcanvasMypageMenuLabel"
        >
            <div className="offcanvas-header">
                <h5 className="offcanvas-title" id="offcanvasMypageMenuLabel">
                    {cm?.translate("My Page Menu")}
                </h5>
                <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                ></button>
            </div>
            <div className="offcanvas-body ">
                {auth?.authUser &&
                    <div className="d-flex align-items-center">
                        {getRenderProfileImage()}
                        <h5 className="text-center ms-2 mb-0">{auth.authUser?.user_business_profile?.company_name || auth.authUser?.user_profile?.name || cm?.translate("Name was not set")}</h5>
                    </div>
                }
                <div className="mt-2">
                    {auth.authUser && <button onClick={logout} className="btn btn-sm btn-light w-100">{cm?.translate("Logout")}</button>}
                    {!auth.authUser && <button onClick={() => window.location = "/login"} className="btn btn-sm btn-outline-success w-100">{cm?.translate("Login")}</button>}

                </div>
                <div className="list-group mt-4">
                    <button
                        className="list-group-item list-group-item-action d-flex align-items-center d-inline-block d-md-none"
                        onClick={toIndex}
                    >
                        <BsPostcard className="fs-3" />
                        <span className="ms-2 font-size-12">{cm?.translate("All Ads.")}</span>
                    </button>
                </div>
                <div className="list-group mt-4">
                    <button
                        className="list-group-item list-group-item-action d-flex align-items-center"
                        onClick={toProfile}
                        disabled={!auth.authUser}
                    >
                        <BsFillPersonLinesFill className="fs-3" />
                        <span className="ms-2 font-size-12">{cm?.translate("Profile")}</span>
                    </button>
                    <button
                        className="list-group-item list-group-item-action d-flex align-items-center"
                        onClick={toAds}
                        disabled={!auth.authUser}
                    >
                        <BsPostcard className="fs-3" />
                        <span className="ms-2 font-size-12">{cm?.translate("My Ads.")}</span>
                    </button>
                    {auth?.authUser?.is_there_any_order &&
                        <button
                            className="list-group-item list-group-item-action d-flex align-items-center"
                            onClick={toOrders}
                        >
                            <TfiReceipt className="fs-3" />
                            <span className="ms-2 font-size-12">{cm?.translate("Orders")}</span>
                        </button>
                    }
                    {auth?.authUser?.is_there_any_job_application &&
                        < button
                            className="list-group-item list-group-item-action d-flex align-items-center"
                            onClick={toJobApplication}
                        >
                            <GrDocumentText className="fs-3" />
                            <span className="ms-2 font-size-12">{cm?.translate("Job Applications")}</span>
                        </button>
                    }

                    <button
                        className="list-group-item list-group-item-action d-flex align-items-center"
                        onClick={toInquiries}
                        disabled={!auth.authUser}
                    >
                        <TbMessageQuestion className="fs-3" />
                        <span className="ms-2 font-size-12">{cm?.translate("Inquiries")}</span>
                    </button>
                    <button
                        className="list-group-item list-group-item-action d-flex align-items-center"
                        onClick={toSettings}
                        disabled={!auth.authUser}
                    >
                        <BsFillGearFill className="fs-3" />
                        <span className="ms-2 font-size-12">{cm?.translate("Settings")}</span>
                    </button>
                    {auth.authUser?.is_superuser &&
                        <button
                            className="list-group-item list-group-item-action d-flex align-items-center"
                            onClick={toAdmin}
                        >
                            <FaUserShield className="fs-3" />
                            <span className="ms-2 font-size-12">{cm?.translate("Admin")}</span>
                        </button>
                    }
                </div>
                <div className="d-flex align-items-center justify-content-between my-3 mt-4">
                    <span className="ms-1 me-2">{cm?.translate("Language")}</span>
                    <select name="language" className="form-select w-75" value={cm?.language} onChange={(e) => cm?.setLanguage(e.target.value)}>
                        <option value="en">English</option>
                        <option value="bn">{cm?.translate("Bangla")}</option>
                    </select>
                </div>
            </div>
        </div >
    );
}
export default MyPageMenu;
