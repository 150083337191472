import { useCommon } from "../hooks/useCommon"

const Pagination = ({ totalItems, onChangePage, currentPage = 1 }) => {
    const pageSplit = 100;

    const cm = useCommon();


    const genPageOption = () => {
        const estimatedPages = Math.ceil(totalItems / pageSplit)

        let pages = []

        for (let i = 0; i < estimatedPages; i += 1) {
            pages.push(pages.length + 1)
        }

        pages = pages?.map((i) => <option key={"page_" + i} value={i}>{cm?.translate(i)}</option>)
        return pages
    }

    return <div className="d-flex justify-content-center align-items-center">
        {Math.ceil(totalItems / pageSplit) > 1 &&
            <>
                <span className="me-2">{cm.translate("Page")}</span>
                <select className="form-select w-auto border border-secondary border-opacity-10 py-1" value={currentPage} onChange={onChangePage}>
                    {genPageOption()}
                </select>
            </>
        }
    </div>
}
export default Pagination