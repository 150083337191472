import { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { alert } from "../../utils/utils";
import { useApi } from "../../hooks/useApi";
import useAlert from "../../hooks/useAlert";

const AdPrivacySet = ({ post, setShowPrivacyTypeModal, showPrivacyTypeModal, refreshAd }) => {
    const [privacyTypes, setPrivacyTypes] = useState();
    const [selectedPrivacy, setSelectedPrivacy] = useState(null);
    const api = useApi()
    const alert = useAlert()

    const handleClose = () => {
        setShowPrivacyTypeModal(false);
    }

    const handleSave = async () => {
        try {
            if (selectedPrivacy) {
                const res = await api.updateAd(post?.alias_id, { privacy_type: selectedPrivacy });

                if (res?.alias_id) {
                    refreshAd(res);
                    handleClose();
                }
            }
        } catch (err) {
            handleClose();
            alert.show(err?.response?.data?.msg || err, "error")
        }
    }

    const handleFormInput = (e) => {
        const { name, value } = e.target;
        setSelectedPrivacy(value)
    }

    const getPrivacyTypeFromApi = async (params) => {
        try {
            const res = await api.getPrivacyTypes();
            setPrivacyTypes(res);
        } catch (err) {
            alert.show(err?.message, "error");
        }
    };

    const renderPrivacyTypes = () => {
        return privacyTypes && privacyTypes?.map(p => <option key={"pt_" + p.id} value={p.id}>{p.name}</option>)
    }


    useEffect(() => {
        getPrivacyTypeFromApi();
    }, [])


    return privacyTypes && <>
        <Modal size="sm" scrollable="true" show={showPrivacyTypeModal} onHide={handleClose} centered>
            <Modal.Body>
                <Form.Select name="privacy_type" defaultValue={post?.privacy?.id} onChange={handleFormInput}>
                    {renderPrivacyTypes()}
                </Form.Select>
            </Modal.Body>
            <Modal.Footer>
                <Button className="btn btn-sm btn-success" onClick={handleSave}>OK</Button>
                <Button className="btn btn-sm btn-secondary" onClick={handleClose}>Cancel</Button>
            </Modal.Footer>
        </Modal>
    </>
}
export default AdPrivacySet;