import { useEffect, useState } from "react";
import { useCommon } from "../../hooks/useCommon"
import { useApi } from "../../hooks/useApi"
import useAlert from "../../hooks/useAlert"
import { useAuth } from "../../hooks/useAuth";
import { Modal } from "react-bootstrap";

const BKash = ({ quantity }) => {
    const [show, setShow] = useState(false);
    const [trxId, setTrxId] = useState("");

    const cm = useCommon();
    const api = useApi();
    const alert = useAlert();
    const auth = useAuth();


    const handleClose = () => {
        setShow(false)
    }

    const handleSend = async () => {
        try {
            cm.showLoader(true, "Buying..");
            const res = await api.createCoinTransactions({ quantity: quantity, purpose: "Purchase coins: Transaction ID " + trxId });
            if (res) {
                window.location = "/mypage/profile/?mode=business"
            }
            cm.showLoader(false);
        } catch (err) {
            cm.showLoader(false);
            alert.show(err?.response?.data?.msg || err, "error");
        }
    };

    const handleShow = () => {
        setShow(true);
    };

    return <>
        <button className="ms-3 btn btn-sm btn-outline-danger" onClick={handleShow}>{cm?.translate("bKash")}</button>
        <Modal scrollable={true} show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>{cm?.translate("bKash")}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-4 text-center">
                <span className="d-block font-size-10 text-secondary text-start">{cm?.translate("Double check your Payment and Transaction Id. Invalid Id may disable your Monozuna account.")}</span>
                <span className="d-block font-size-14 mt-4">{cm?.translate("Pay " + quantity + " Taka")}</span>
                <span className="d-block font-size-14 mt-2 fw-semibold">{cm?.translate("bKash 01321747472")}</span>
                < input type="trx_id" className="form-control mt-2" placeholder="Bkash Transaction Id" onChange={e => setTrxId(e.target.value)}></input>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-sm btn-outline-success" onClick={handleSend}>{cm?.translate("Send")}</button>
                <button className="btn btn-sm btn-secondary" onClick={handleClose}>{cm?.translate("Cancel")}</button>
            </Modal.Footer>
        </Modal >
    </>
}

export default BKash