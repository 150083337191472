import { useState } from "react";
import { useEffect } from "react";
import PostTemplate from "./PostTemplate";
import { useApi } from "../hooks/useApi";
import { useCommon } from "../hooks/useCommon";
import useAlert from "../hooks/useAlert";
import { GrFormNext } from "react-icons/gr";

const SimilarAds = ({ post }) => {
    const [similarAds, setSimilarAds] = useState([])
    const api = useApi();
    const cm = useCommon();
    const alert = useAlert();

    const getSimilarAdsFromApi = async () => {
        try {
            cm.showLoader(true);
            const res = await api.getSimilarAds(post?.alias_id);
            setSimilarAds(res);
            cm.showLoader(false);
        } catch (err) {
            cm.showLoader(false);
            alert.show(err?.message, "error")
        }
    };

    useEffect(() => {
        post && getSimilarAdsFromApi()
    }, [post])


    const renderSimilarAds = () => {
        return similarAds?.map((a, i) => <PostTemplate key={"similar_post_" + i} post={a} />)
    }

    return similarAds?.length > 0 && <div className="border-top bg-light p-3 overflow-scroll" style={{ maxHeight: 300 }}>
        <div className="d-flex justify-content-between align-items-center mb-3">
            <h5 className="fw-semibold mb-0">{post?.category?.group?.id === 2 ? cm?.translate("You may fit") : cm?.translate("You may like")}</h5>
            <button className="btn btn-sm btn-white py-1" title={cm.translate("Next")} onClick={getSimilarAdsFromApi}><GrFormNext className="fs-5" /> </button>
        </div>
        <div className="row g-0 m-0">
            {renderSimilarAds()}
        </div>
    </div>
}
export default SimilarAds