import { useEffect, useState } from "react";
import useAlert from "../../hooks/useAlert";
import { useApi } from "../../hooks/useApi";
import { useAuth } from "../../hooks/useAuth";
import { useCommon } from "../../hooks/useCommon"
import ImageUploader from "../ImageUploader"
import PreviewMediaFile from "../PreviewMediaFile";

const GeneralProfile = () => {
    const [profile, setProfile] = useState({})
    const cm = useCommon();
    const api = useApi();
    const alert = useAlert();
    const auth = useAuth();

    useEffect(() => {
        setProfile({
            alias_id: auth?.authUser?.alias_id,
            email_or_phone: auth?.authUser?.email_or_phone,
            is_superuser: auth?.authUser?.is_superuser,
            is_active: auth?.authUser?.is_active,
            user_profile: auth?.authUser?.user_profile,
        })
    }, [])

    // useEffect(() => {
    //     console.log(profile)
    // }, [profile])


    const handleUploadProfileImage = (e) => {
        let up = { ...profile?.user_profile }
        // up.profile_image = e[0]?.base64;
        up["changed_profile_image"] = e[0]?.base64;
        setProfile({ ...profile, user_profile: up })
    }

    const handleFormInput = (e) => {
        const { name, value } = e.target;
        let up = { ...profile?.user_profile }

        if (name === "email_or_phone" && auth?.authUser.email_or_phone.includes("@")) {
            if (/^\d+$/.test(value) || value === "") {
                up = { ...up, [name]: value };
            }
        }
        else {
            up = { ...up, [name]: value };
        }
        setProfile({ ...profile, user_profile: up });
    };

    const removeProfilePhoto = () => {
        let up = { ...profile?.user_profile, profile_image: "", deleted_profile_image: profile?.user_profile?.profile_image }
        delete up?.changed_profile_image;
        setProfile({ ...profile, user_profile: up });
    };

    const handleSave = async () => {
        try {
            cm?.showLoader(true, "Saving..")

            const res = await api.updateUserProfile(auth.authUser?.alias_id, profile?.user_profile);
            if (res) {
                const au = { ...auth.authUser, user_profile: res };
                auth.updateAuthUser(au);
                setProfile({ ...profile, user_profile: res })
                alert.toast(cm?.translate("General Profile was saved"), "success")
                cm?.showLoader(false)
                return true;
            }

        } catch (err) {
            cm?.showLoader(false)
            alert.show(err?.response?.data?.msg || err?.msg || err, "error")
            return false
        }
    };

    return <>
        <div className="row m-0 d-flex align-items-center mb-1 bg-light p-2 px-0">
            <div className="col-3">
                <span className="text-danger">{cm?.translate("Login Id")}</span>
            </div>
            <div className="col d-flex justify-content-start align-items-center">
                <span className="text-danger">{auth?.authUser?.email_or_phone}</span>
            </div>
        </div>
        <div className="row m-0 d-flex align-items-center mb-1 bg-light p-2 px-0">
            <div className="col-3">
                <span>{cm?.translate("Profile Photo")}</span>
            </div>
            <div className="col d-flex justify-content-start align-items-end">
                {(profile?.user_profile?.changed_profile_image || profile?.user_profile?.profile_image) && <PreviewMediaFile
                    src={
                        profile?.user_profile?.changed_profile_image || profile?.user_profile?.profile_image
                    }
                    removeFromPreview={removeProfilePhoto}
                />}
                <div className="ms-2">
                    <ImageUploader
                        setNewFiles={handleUploadProfileImage}
                        label={profile?.user_profile?.profile_image ? cm?.translate("Change Photo") : cm?.translate("Upload Photo")}
                        multipleUpload="false"
                        width="auto"
                        clearPreview={true}
                    />
                </div>
            </div>
        </div>

        <div className="row m-0 d-flex align-items-center mb-1 bg-light p-2 px-0">
            <div className="col-3">
                <span>{cm?.translate("Name")}</span>
            </div>
            <div className="col">
                <input
                    type="text"
                    name="name"
                    className="form-control"
                    value={profile?.user_profile?.name || ""}
                    onChange={handleFormInput}
                    required={true}
                />
            </div>
        </div>

        <div className="row m-0 d-flex align-items-center mb-1 bg-light p-2 px-0">
            <div className="col-3">
                <span>{cm?.translate("Gender")}</span>
            </div>
            <div className="col">
                <select
                    name="gender"
                    className="form-select"
                    value={profile?.user_profile?.gender || ""}
                    onChange={handleFormInput}
                >
                    <option value="">{cm?.translate("Select Gender")}</option>
                    <option value="m">{cm?.translate("Male")}</option>
                    <option value="f">{cm?.translate("Female")}</option>
                    <option value="o">{cm?.translate("Other")}</option>
                </select>
            </div>
        </div>

        <div className="row m-0 d-flex align-items-center mb-1 bg-light p-2 px-0">
            <div className="col-3">
                <span>{cm?.translate("Date of Birth")}</span>
            </div>
            <div className="col">
                <input
                    type="date"
                    name="dob"
                    className="form-control"
                    value={profile?.user_profile?.dob || ""}
                    onChange={handleFormInput}
                />
            </div>
        </div>


        <div className="row m-0 d-flex align-items-center mb-1 bg-light p-2 px-0">
            <div className="col-3">
                {auth.authUser?.email_or_phone?.includes("@") ?
                    <>
                        <span>{cm?.translate("Phone")}</span>
                    </>
                    : <span>{cm?.translate("Email")}</span>
                }
            </div>
            <div className="col">
                <input
                    type="text"
                    name="email_or_phone"
                    maxLength={auth.authUser?.email_or_phone?.includes("@") ? 14 : 100}
                    value={profile?.user_profile?.email_or_phone || ""}
                    className="form-control"
                    onChange={handleFormInput}
                />
            </div>
        </div>

        <div className="row m-0 d-flex align-items-center mb-1 bg-light p-2 px-0">
            <div className="col-3">
                <span>{cm?.translate("Address")}</span>
            </div>
            <div className="col">
                <textarea
                    type="text"
                    rows={2}
                    name="address"
                    value={profile?.user_profile?.address || ""}
                    className="form-control"
                    onChange={handleFormInput}
                ></textarea>
            </div>
        </div>
        <div className="row m-0 d-flex align-items-center mb-1 bg-light p-3 px-0">
            <div className="col d-flex justify-content-center">
                {
                    <button className="btn btn-sm btn-success" onClick={handleSave}>{cm?.translate("Save Changes")}</button>

                }
            </div>
        </div>

    </>

}
export default GeneralProfile